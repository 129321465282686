import React, { useState, useEffect } from "react";

// package
import axios from "axios";
import { Button, makeStyles } from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";

// component
import HeroMembers from "../../components/members/HeroMembers";
import BenefitMembers from "../../components/members/BenefitMembers";
import SupplierWorkReseller from "../../components/members/SupplierWorkReseller";
import ProcessMembers from "../../components/members/ProcessMembers";
import ConditionMembers from "../../components/members/ConditionMembers";
import KeyFeatureMembers from "../../components/members/KeyFeatureMembers";

// asset
import heroBackground from "../../assets/images/members/reseller/hero.png";
import WorkOneAgency from "../../assets/images/members/reseller/agency/work-1.png";
import WorkTwoAgency from "../../assets/images/members/reseller/agency/work-2.png";
import WorkThreeAgency from "../../assets/images/members/reseller/agency/work-3.png";
import WorkFourAgency from "../../assets/images/members/reseller/agency/work-4.png";
import WorkOneCorporate from "../../assets/images/members/reseller/corporate/work-1.png";
import WorkTwoCorporate from "../../assets/images/members/reseller/corporate/work-2.png";
import WorkThreeCorporate from "../../assets/images/members/reseller/corporate/work-3.png";
import WorkFourCorporate from "../../assets/images/members/reseller/corporate/work-4.png";
import WorkOnePetra from "../../assets/images/travelikuy/petra/work-1.png";
import WorkTwoPetra from "../../assets/images/travelikuy/petra/work-2.png";
import WorkThreePetra from "../../assets/images/travelikuy/petra/work-3.png";
import WorkFourPetra from "../../assets/images/travelikuy/petra/work-4.png";
import processImage from "../../assets/images/members/reseller/process.png";
import processImage_EN from "../../assets/images/members/reseller/process_EN.jpg";
import processImageCorporate from "../../assets/images/members/reseller/process_corporate.png";
import processImageCorporate_EN from "../../assets/images/members/reseller/process_corporate_EN.jpg";
import workBackground from "../../assets/images/members/reseller/bg.png";


const useStyles = makeStyles((theme) => ({
  buttonOption: {
    backgroundColor: "white",
    width: "50%",
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  optionTitle: {
    textAlign: "center",
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      margin: "2rem auto",
    },
  },
}));


const Reseller = ({ url }) => {
  const [contentWorkAgent, setContentWorkAgent] = useState(null);
  const [contentWorkCorporate, setContentWorkCorporate] = useState(null);
  const [contentWorkPetra, setContentWorkPetra] = useState(null);
  const [contentWork, setContent] = useState("agency");
  const [contentFeature, setContentFeature] = useState(null);
  const [contentFeaturePetra, setContentFeaturePetra] = useState(null);
  const [heroContent, setHeroContent] = useState(null);
  const [offerContent, setOfferContent] = useState(null);
  const [offerContentPetra, setOfferContentPetra] = useState(null);
  const [conditionContent, setConditionContent] = useState(null);
  const [conditionContentPetra, setConditionContentPetra] = useState(null);
  const [agentProcess, setAgentProcess] = useState(true);
  const [videoContent, setVideoContent] = useState({});

  const [petraPage, setPetraPage] = useState(false);
  const [benefitTitle, setBenefitTitle] = useState("FOR PETRA");

  const [titleWork, setTitleWork] = useState(`HOW PETRA WORKS`);


  const [triggerSub, setTriggerSub] = useState(`petra`);
  const [colorButtonOne, setColorButtonOne] = useState("yellow");
  const [colorButtonTwo, setColorButtonTwo] = useState("white");
  const [colorButtonThree, setColorButtonThree] = useState("white");

  const [oneClicked, setOneClicked] = useState(false);
  const [twoClicked, setTwoClicked] = useState(false);
  const [threeClicked, setThreeClicked] = useState(false);

  const btnData ={
    title: "Reseller List",
    link: "/reseller-list"
  }

  const classes = useStyles();

  useEffect(() => {
    axios
      .get(`${url}/MembersReseller`)
      .then((res) => {
        const dataRaw = res.data.message;
        if (dataRaw) {
          // Hero
          const hero = {
            title: dataRaw.Hero.Title,
            description: dataRaw.Hero.Description_RT,
            footer:
              "ARE YOU AN AGENT OR RESELLER LOOKING TO GAIN COMPETITIVE ADVANTAGE?",
          };
          setHeroContent(hero);
          setVideoContent(dataRaw.Video);

          // What We Offer
          let whatWeOffer = [];
          if (dataRaw.WhatWeOfferTitle) {
            const ordered = Object.entries(dataRaw.WhatWeOfferTitle)
              .sort()
              .reduce((o, [k, v]) => ((o[k] = v), o), {});
            const orderedDesc = Object.entries(dataRaw.WhatWeOfferDescription)
              .sort()
              .reduce((o, [k, v]) => ((o[k] = v), o), {});

            for (let key in ordered) {
              whatWeOffer.push({ title: dataRaw.WhatWeOfferTitle[key] });
            }
            let index = 0;
            for (let key in orderedDesc) {
              whatWeOffer[index].description =
                dataRaw.WhatWeOfferDescription[key];
              index++;
            }
            setOfferContent(whatWeOffer);
          }

          // What We Offer petra
          let whatWeOfferPetra = [];
          if (dataRaw.PetraWhatWeOfferTitle) {
            const ordered = Object.entries(dataRaw.PetraWhatWeOfferTitle)
              .sort()
              .reduce((o, [k, v]) => ((o[k] = v), o), {});
            const orderedDesc = Object.entries(dataRaw.PetraWhatWeOfferDescription)
              .sort()
              .reduce((o, [k, v]) => ((o[k] = v), o), {});

            for (let key in ordered) {
              whatWeOfferPetra.push({ title: dataRaw.PetraWhatWeOfferTitle[key] });
            }
            let index = 0;
            for (let key in orderedDesc) {
              whatWeOfferPetra[index].description =
                dataRaw.PetraWhatWeOfferDescription[key];
              index++;
            }
            setOfferContentPetra(whatWeOfferPetra);
          }

          // How Corporate Works
          if (dataRaw.HowCorporateWorksTitle) {
            const supplierWorks = {
              one: {
                image: WorkOneCorporate,
                title: dataRaw.HowCorporateWorksTitle.Title1,
                desc: dataRaw.HowCorporateWorksDescription.Description1,
              },
              two: {
                image: WorkTwoCorporate,
                title: dataRaw.HowCorporateWorksTitle.Title2,
                desc: dataRaw.HowCorporateWorksDescription.Description2,
              },
              three: {
                image: WorkThreeCorporate,
                title: dataRaw.HowCorporateWorksTitle.Title3,
                desc: dataRaw.HowCorporateWorksDescription.Description3,
              },
              four: {
                image: WorkFourCorporate,
                title: dataRaw.HowCorporateWorksTitle.Title4,
                desc: dataRaw.HowCorporateWorksDescription.Description4,
              },
            };
            setContentWorkCorporate(supplierWorks);
          }

          if (dataRaw.HowAgencyOrSubAgentWorksTitle) {
            const supplierWorks = {
              one: {
                image: WorkOneAgency,
                title: dataRaw.HowAgencyOrSubAgentWorksTitle.Title1,
                desc: dataRaw.HowAgencyOrSubAgentWorksDescription.Description1,
              },
              two: {
                image: WorkTwoAgency,
                title: dataRaw.HowAgencyOrSubAgentWorksTitle.Title2,
                desc: dataRaw.HowAgencyOrSubAgentWorksDescription.Description2,
              },
              three: {
                image: WorkThreeAgency,
                title: dataRaw.HowAgencyOrSubAgentWorksTitle.Title3,
                desc: dataRaw.HowAgencyOrSubAgentWorksDescription.Description3,
              },
              four: {
                image: WorkFourAgency,
                title: dataRaw.HowAgencyOrSubAgentWorksTitle.Title4,
                desc: dataRaw.HowAgencyOrSubAgentWorksDescription.Description4,
              },
            };
            setContentWorkAgent(supplierWorks);
            setContent(supplierWorks);
          }

          if (dataRaw.HowPetraWorksTitle) {
            const supplierWorks = {
              one: {
                image: WorkOnePetra,
                title: dataRaw.HowPetraWorksTitle.Title1,
                desc: dataRaw.HowPetraWorksDescription.Description1,
              },
              two: {
                image: WorkTwoPetra,
                title: dataRaw.HowPetraWorksTitle.Title2,
                desc: dataRaw.HowPetraWorksDescription.Description2,
              },
              three: {
                image: WorkThreePetra,
                title: dataRaw.HowPetraWorksTitle.Title3,
                desc: dataRaw.HowPetraWorksDescription.Description3,
              },
              four: {
                image: WorkFourPetra,
                title: dataRaw.HowPetraWorksTitle.Title4,
                desc: dataRaw.HowPetraWorksDescription.Description4,
              },
            };
            setContentWorkPetra(supplierWorks);
          }

          // Condition
          const conditions = [];
          if (dataRaw.Conditions) {
            for (let key in dataRaw.Conditions) {
              conditions.push(dataRaw.Conditions[key]);
            }
            setConditionContent(conditions);
          }

          // Condition Petra
          const conditionsPetra = [];
          if (dataRaw.ConditionsPetra) {
            for (let key in dataRaw.ConditionsPetra) {
              conditionsPetra.push(dataRaw.ConditionsPetra[key]);
            }
            setConditionContentPetra(conditionsPetra);
          }

          // Key Feature
          if (dataRaw.KeyFeaturesForResellerOnlineBillingDocument) {
            const dataOnlineBilling = [];
            for (let key in dataRaw.KeyFeaturesForResellerOnlineBillingDocument) {
              dataOnlineBilling.push(
                dataRaw.KeyFeaturesForResellerOnlineBillingDocument[key]
              );
            }
            const dataSupportingTools = [];
            for (let key in dataRaw.KeyFeaturesForResellerSupportingTools) {
              dataSupportingTools.push(
                dataRaw.KeyFeaturesForResellerSupportingTools[key]
              );
            }

            const webPortalManagement = [];
            for (let key in dataRaw.KeyFeaturesForResellerWebPortalManagement) {
              webPortalManagement.push(
                dataRaw.KeyFeaturesForResellerWebPortalManagement[key]
              );
            }

            const tempData = {
              one: {
                title: "WEB PORTAL MANAGEMENT",
                data: webPortalManagement,
              },
              two: {
                title: "ONLINE BILLING DOCUMENT",
                data: dataOnlineBilling,
              },
              three: {
                title: "SUPPORTING TOOLS",
                data: dataSupportingTools,
              },
            };

            setContentFeature(tempData);
          }

          // Key Feature Petra
          if (dataRaw.KeyFeaturesForPetraOnlineReport) {
            const dataOnlineBilling = [];
            for (let key in dataRaw.KeyFeaturesForPetraOnlineReport) {
              dataOnlineBilling.push(
                dataRaw.KeyFeaturesForPetraOnlineReport[key]
              );
            }
            const dataSupportingTools = [];
            for (let key in dataRaw.KeyFeaturesForPetraOthers) {
              dataSupportingTools.push(
                dataRaw.KeyFeaturesForPetraOthers[key]
              );
            }

            const webPortalManagement = [];
            for (let key in dataRaw.KeyFeaturesForPetraWebPortal) {
              webPortalManagement.push(
                dataRaw.KeyFeaturesForPetraWebPortal[key]
              );
            }

            const tempDataPetra = {
              one: {
                title: "WEB PORTAL MANAGEMENT",
                data: webPortalManagement,
              },
              two: {
                title: "ONLINE Report",
                data: dataOnlineBilling,
              },
              three: {
                title: "OTHERS",
                data: dataSupportingTools,
              },
            };

            setContentFeaturePetra(tempDataPetra);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [url]);

  const handleProcessImage = (type) => {
    if (type === "agent") {
      setAgentProcess(true);
    } else {
      setAgentProcess(false);
    }
  };

  
  const handleButtonOne = () => {
    setColorButtonOne("yellow");
    setColorButtonTwo("white");
    setColorButtonThree("white");
    setOneClicked(true);
    setTwoClicked(false);
    setThreeClicked(false);
    setTriggerSub("agency");
    setPetraPage(false);
    setBenefitTitle("FOR AGENCY");
    setTitleWork("HOW AGENCY WORKS");
    setContent(contentWorkAgent);
  };

  const handleButtonTwo = () => {
    setColorButtonOne("white");
    setColorButtonTwo("yellow");
    setColorButtonThree("white");
    setOneClicked(false);
    setTwoClicked(true);
    setThreeClicked(false);
    setTriggerSub("corporate");
    setPetraPage(false);
    setTitleWork("HOW CORPORATE WORKS");
    setBenefitTitle("FOR CORPORATE");
    setContent(contentWorkCorporate);
  };
  
  const handleButtonThree = () => {
    setColorButtonOne("white");
    setColorButtonTwo("white");
    setColorButtonThree("yellow");
    setOneClicked(false);
    setTwoClicked(false);
    setThreeClicked(true);
    setTriggerSub("petra");
    setPetraPage(true);
    setBenefitTitle("FOR PETRA");
    setTitleWork("HOW PETRA WORKS");
    setContent(contentWorkPetra);
  };
  
  return (
    <div className="members-page">
      <HeroMembers 
        data={heroContent} 
        btnData={btnData} 
        backgroundHero={heroBackground} 
        btnHero={true}
        video={videoContent}
      />
      <br/>
      <br/>
      <br/>
      <div className={`button-container ${classes.buttonOption}`}>
        <Button
          style={{
            fontFamily: "Bristone",
            backgroundColor: colorButtonOne,
            borderRadius: "10rem",
            color:
            oneClicked || colorButtonOne === "yellow"
                ? "#07AED6"
                : "#707070",
            width: "33.3%",
          }}
          className="button-temp"
          onClick={handleButtonOne}
        >
          {`AGENCY OR SUB-AGENT`}
        </Button>
        <Button
          style={{
            fontFamily: "Bristone",
            backgroundColor: colorButtonTwo,
            borderRadius: "10rem",
            color:
            twoClicked || colorButtonTwo === "yellow"
                ? "#07AED6"
                : "#707070",
            width: "33.3%",
          }}
          className="button-temp"
          onClick={handleButtonTwo}
        >
          {`CORPORATE`}
        </Button>
        <Button
          style={{
            fontFamily: "Bristone",
            backgroundColor: colorButtonThree,
            borderRadius: "10rem",
            color:
              threeClicked || colorButtonThree === "yellow"
                ? "#07AED6"
                : "#707070",
            width: "33.3%",
          }}
          className="button-temp"
          onClick={handleButtonThree}
        >
          {`PERSONAL TRAVELI (PETRA)`}
        </Button>
      </div>
      <BenefitMembers
        textHeader={"FOR RESELLER"}
        textSubHeader={"WHAT WE OFFER"}
        data={ petraPage ? offerContentPetra : offerContent}
      />
      <div style={{ margin: "4rem auto 0 auto" }}>
        <ScrollAnimation animateIn="fadeIn">
          <div className={classes.optionTitle}
            style={{
              color: "#07AED6",
              marginBottom: "2rem",
              textAlign: "center",
            }}>
            {/* <h6>{`DO YOU WANT TO HAVE EXTRA INCOME?`}</h6> */}
            <ScrollAnimation animateIn="zoomIn">
              <h2>{"HOW RESELLER WORKS"}</h2>
            </ScrollAnimation>
          </div>
          
        </ScrollAnimation>
      </div>
      <SupplierWorkReseller
        contentAgent={contentWork}
        workBackground={workBackground}
        handleProcessImage={handleProcessImage}
      />
      <ProcessMembers
        processImg={agentProcess ? processImage : processImageCorporate}
        processImage_EN={agentProcess ? processImage_EN : processImageCorporate_EN }
      />
      <ConditionMembers 
        data={ petraPage ? conditionContentPetra : conditionContent } 
      />
      <KeyFeatureMembers
        content={ petraPage ? contentFeaturePetra : contentFeature}
        title={"KEY FEATURES FOR RESELLER"}
      />
    </div>
  );
};

export default Reseller;
