import React from 'react'

const ProcessMembers = ({processImg, processImage_EN}) => {
    const process = localStorage.getItem("language") == "id" ? processImg : processImage_EN
    return (
        <div style={{margin: '5rem 0 0 0'}}>
            <img src={process} alt="proccess traveli" width="100%"/>
        </div>
    )
}

export default ProcessMembers
