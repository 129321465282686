import React from "react";
import "../pages/members/members.css";
import { useState, useEffect } from "react";

const VideoFrame = ({ dataVideo }) => {
  const [videoUrl, setVideoUrl] = useState();

  useEffect(() => {
    if( dataVideo.show == true && dataVideo.VideoUrl.split("/")[2] == "youtu.be"){
      setVideoUrl(dataVideo.VideoUrl.split("/")[3])
    }
  },[dataVideo])

  return (
    <>
      { dataVideo.show == true && dataVideo.VideoUrl.split("/")[2] == "youtu.be" ? 
          <iframe className="mt-4" allow="fullscreen" 
          src={"https://www.youtube.com/embed/" + videoUrl}
          >
          </iframe>
        :
        null
      }
    </>
  );
};

export default VideoFrame;
